// Importing Fonts
@font-face {
  font-family: "NunitoSans";
  src: url("./assets/fonts/Montserrat.ttf") format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.react-tel-input-style {
  width: 100% !important;
  margin: 10px 0px;
}

.apexcharts-toolbar{
  display: none !important
}
.mapTooltip {
  background: #000000e0;
  color: white;
  font-weight: 500;
  font-size: 13px;
  border-radius: 5px;
  padding: 8px;
  width: auto;
  white-space: pre-wrap;
}

.typing-indicator {
  background-color: white;
  width: auto;
  border-radius: 10px;
  display: table;
  margin: 10px;
  margin-top: 15px;
  position: relative;
  -webkit-animation: 2s bulge infinite ease-out;
  animation: 2s bulge infinite ease-out;
}
/* .typing-indicator:before, .typing-indicator:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #E6E7ED;
  } */
.typing-indicator:after {
  height: 10px;
  width: 10px;
  left: -10px;
  bottom: -10px;
}
.typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #59abfd;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.typing-indicator span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
  animation: 1s blink infinite 0.3333s;
}
.typing-indicator span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
  animation: 1s blink infinite 0.6666s;
}
.typing-indicator span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
  animation: 1s blink infinite 0.9999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.bot-avatar {
  box-shadow: 0px 0px 12px 0 rgba(0, 0, 0, 0.329);
  .MuiAvatar-img {
    width:30px;
    height:30px;
    border-radius: 10px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.push-notif {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: #f4f4f4f7;
    z-index: 9999;
    top: 0px;
    width: 100%;
    padding: 10px;
    flex-wrap: wrap;
    color: #0066cc;
}
.save-card {
    bottom: 0;
    background-color: white;
    z-index: 1000;
}
.docs-video-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 30px;
  color: #0067cb;
}

.doc-parent {
  display: flex;
  flex-wrap: wrap;
  background: #f4f4f4;
}
.pdf-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .docs-content {
    flex-direction: column;
    align-items: center;
  }

  .table-content {
    margin: 10px;
    margin-top: 20px;
    font-family: '';
  }
}

.pdf-card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 150px;
  background: white;
  display: flex;
  flex-direction: column;
 justify-content: center;
}

.pdf-card-contact {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-radius: 50px;
  padding: 10px;
  margin: 10px;
  width: 175px;
  background: white;
  display: flex;
  flex-direction: column;
 justify-content: center;
 text-align: center;
 height: 160px;
 background: #0066cc;
 color: white;
 cursor: pointer;
}
.MuiFormLabel-asterisk{
  font-family: auto;
    color: red;
}
.MuiFormGroup-root {
  margin: 10px 0px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #0066cc !important;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #0066cc !important
}
.App-link {
  color: #61dafb;
}
.MuiCardHeader-content {
  text-transform: capitalize;
}
.doc-home {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000003d;
}

.docs-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #f4f4f4;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: calc(100 * var(--vh));
  overflow: auto;
  height: calc(100 * var(--vh));
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#alert-dialog-description,
#alert-dialog-title {
  font-weight: bold;
}

.axis {
  font-size: 15px;
}

.careplaner-btn {
    position: absolute;
    opacity: 0.6;
    border-radius: 18px;
    padding: 5px;
}

.special-label::after {
  content:" *";
  color: red;
}

#more-icon-main {
  position: absolute;
  top: 10px;
  right: 0px;
}
#more-icon-main {
  .MuiSvgIcon-root {
    font-size: 2.2rem
  }
}

.more.disable {
  opacity: 6;
  cursor: none;
}

.menu-list {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 175px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #f1f4f6;
  background-color: #ffffff;
  z-index: 1;
}

.customRow {
  max-height: fit-content !important;
}
.customCell {
  overflow: auto;
}
.logout {
  background-image: url(./assets/img/logout.svg);
}

.profile {
  background-image: url(./assets/img/user.svg);
}

.home {
  background-image: url(./assets/img/home-line.svg);
}

.menu-ele {
  display: flex;
  height: 48px;
  width: 180px;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  color: #475562;
  cursor: pointer;
}

.more {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
  color:#0066cc;
}


.menu-ele:nth-child(even) { 
  background-color: #ebebeb;
}

.refresh,
.logout,
.home,
.profile,
.help {
  width: 18px;
  height: 18.8px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 15px 0 25px;
}

body {
  font-family: NunitoSans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  position: fixed;
  top: 0px;
  bottom: 0;
  right: 0;
  display: flex;
  left: 0;
  flex-direction: column;
  font-size: 16px;
  // background-image: url(../img/bg.svg);
  // background-repeat: no-repeat;
  // background-size: 600px;
  // background-position: top;

  .abText {
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    word-break: normal;
    margin: 5px 0;
  }
  .animated-header {
    height: 100vh;
  }
  .abLink {
    color: #2d5eff;
    text-decoration: unset;
  }

  @media (min-width: 641px) {
    body {
      background-size: cover;
    }
  }
}

@media screen and (max-height: 700px) {
  body {
    position: inherit;
    overflow: auto;
  }
}

// @media screen and (max-height: 640px) {
//   .desc {
//     font-size: 12px !important;
//   }

//   #start-btn-eldercare,
//   #start-btn-medicare,
//   #start-btn-answers {
//     height: 35px !important;
//     font-size: 14px !important;
//   }
//   .intro {
//     font-size: 16px !important;
//   }
// }

/** Community CSS **/

$nav-height: 70px;
$community-nav-height: 40px;

#community-overlay,
#topic-overlay,
#profile-overlay,
#question-overlay {
  //transform: translateY(60px);
  margin-top: $nav-height;
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: calc(100% - 100px);
}

#community-open-button {
  background-image: url(./assets/img/community-icon-inactive.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

.MuiChip-clickable {
  margin: 5px !important;
}

.MuiCardHeader-content {
text-transform: none;
}
.activeChip {
  background-color: #0869cd !important;
  color: white !important;
  font-weight: bold;
}

#community-open-button.active,
#open-button .active {
  background-image: url(./assets/img/community-icon-active.svg);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

#community-notification-button,
#topic-notification-button,
#question-notification-button {
  background-image: url(./assets/img/notification.svg);
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

#community-notification-button.active,
#toipic-notification-button.active,
#question-notification-button.active {
  background-image: url(./assets/img/notification.active.svg);
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

#community-notification-button.available,
#topic-notification-button.available,
#question-notification-button.available {
  background-image: url(./assets/img/notification-available.svg);
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

#community-notification-button.available.active,
#topic-notification-button.available.active,
#question-notification-button.available.active {
  background-image: url(./assets/img/notification-available-active.svg);
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

#community-profile-button,
#topic-profile-button,
#question-profile-button {
  background-image: url(./assets/img/profile.svg);
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

#community-profile-button.active,
#topic-profile-button.active,
#question-profile-button.active {
  background-image: url(./assets/img/profile-active.svg);
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

.header-content-right {
  display: flex;
  align-items: center;
}

#community-close-button,
#topic-close-button,
#profile-close-button,
#question-close-button {
  background-image: url(./assets/img/close-black.svg);
  height: 25px;
  width: 21px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  cursor: pointer;
}

#community-overlay .community-header,
#topic-overlay .topic-header,
#profile-overlay .profile-header,
#question-overlay .question-header {
  display: flex;
  height: $community-nav-height;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  border-bottom: 0.55px solid lightgray;
  background-color: #f1f4f6;
}

.community-header .header-content-left,
.topic-header .header-content-left,
.profile-header .header-content-left,
.question-header .header-content-left {
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
}

#community-home,
#topic-home,
#profile-home,
#question-home {
  background-image: url(./assets/img/home.svg);
  height: 25px;
  width: 21px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
  cursor: pointer;
}

#community-home.active,
#topic-home.active,
#profile-home.active,
#question-home.active {
  background-image: url(./assets/img/home-active.svg);
  height: 25px;
  width: 21px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
  cursor: pointer;
}

.mobile.only.mobile-main-menu {
  display: none !important;
}

.logo-img {
  background-image: url(./assets/img/logo.svg);
  background-repeat: no-repeat;
  width: 155px;
  height: 80px;
  margin-bottom: 20px;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 28px;
  }
}

@keyframes sound-medium {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 20px;
  }
}

@keyframes sound-small {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 15px;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInTop {
  animation-name: fadeInTop;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.community-div {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.css-dwgbff {
  animation: animation-roll 0.75s 0s infinite linear !important;
  width: 96px !important;
  height: 96px !important;
}

@keyframes animation-roll {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    -ms-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}


.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.MuiCard-root {
  overflow: auto !important;
}

.MuiCard-root::-webkit-scrollbar{
  width:7px;
  background-color:rgb(214, 214, 214);
  border-radius:5px;
  }

  .MuiCard-root::-webkit-scrollbar-thumb{
    background:rgb(179, 179, 179);
    border-radius:5px;
  }


.profile-container {
  width: 100vw;
}

#root {
  width: 100%
}


@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
  .CardContainer {
    max-height: 90vh;
  }
}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 
  .CardContainer {
    max-height: 90vh;
  }
}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
  .CardContainer {
    max-height: 88vh;
  }  
}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .CardContainer {
    max-height: 88vh;
  }
  
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 
  .CardContainer {
    max-height: 95vh;
  }
}

@media (min-width:1281px) { /* hi-res laptops and desktops */ 
  .CardContainer {
    max-height: 95vh;
  }  
}

.introjs-tooltiptext {
  font-family: NunitoSans, sans-serif !important;
}

.introjs-button {
  font-family: NunitoSans, sans-serif !important;
  background-color: #0066cc !important;
  color: white !important;
  text-shadow: none !important;
  border:  1px solid #0065cc !important;
}

.introjs-disabled {
  opacity: 0.5
}

.yes-change {
  background-color: green
}

.no-change {
  background: red
}

.dont-know {
  background: white;
  border-color: black;
  border: 1px solid
}

