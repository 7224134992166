
.page-header {
  display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100 * var(--vh));
    flex-direction: column;
    overflow: auto;
    height: calc(100 * var(--vh));
}

#root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
}

body {
  font-family: NunitoSans;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 5%;
  bottom: 0;
  right: 0;
  left: 0;
  scrollbar-gutter: stable both-edges;
}

.bounce {
  bottom: 0;
  margin-left: -105px;
  height: 50px;
  width: 50px;
  animation: bounce 3s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

.fadeInBottom {
  animation-name: fadeInBottom;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}
